import Navigation from "../navigation/navigation";
import './impressum.css';

const Impressum = () => {
  return (
    <div className="impressum">
      <Navigation />
      <div className="impressum-text">
        <h1>Impressum</h1>
        <h3>Kontakt</h3>
        <p>Gerry Trecha<br />
          Gewerbestr. 20<br />
          76467 Bietigheim<br />
          Baden-Württemberg<br />
          Deutschland<br />
          Telefon: +49 (0) 7245 / 938384<br />
          Fax: +49 (0) 7245 / 938387<br />
          Mobil: +49 (0) 172 / 7353265<br />
          E-Mail: info@topsantiago.de
        <h3>Haftungsausschluss</h3>
        <h4>1. Inhalt des Onlineangebotes</h4>
        <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.  Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
        <h4>2. Verweise und Links</h4>
        <p>Bei  direkten  oder  indirekten  Verweisen  auf  fremde  Webseiten  ("Hyperlinks"),  die  außerhalb  des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der  Autor  erklärt  hiermit  ausdrücklich,  dass  zum  Zeitpunkt  der  Linksetzung  keine  illegalen  Inhalte  auf  den  zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und  insbesondere  für  Schäden,  die  aus  der  Nutzung  oder  Nichtnutzung  solcherart  dargebotener  Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
        <h4>3. Urheber- und Kennzeichenrecht</h4>
        <p>Der  Autor  ist  bestrebt,  in  allen  Publikationen  die  Urheberrechte  der  verwendeten  Grafiken,  Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle  innerhalb  des  Internetangebotes  genannten  und  ggf.  durch  Dritte  geschützten  Marken-  und  Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen  eingetragenen  Eigentümer.  Allein  aufgrund  der  bloßen  Nennung  ist  nicht  der  Schluss  zu  ziehen,  dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das  Copyright  für  veröffentlichte,  vom  Autor  selbst  erstellte  Objekte  bleibt  allein  beim  Autor  der  Seiten.  Eine Vervielfältigung   oder   Verwendung   solcher   Grafiken,   Tondokumente,   Videosequenzen   und   Texte   in   anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>
        <h4>4. Datenschutz</h4>
        <p>Sofern  innerhalb  des  Internetangebotes  die  Möglichkeit  zur  Eingabe  persönlicher  oder  geschäftlicher  Daten (Emailadressen,  Namen,  Anschriften)  besteht,  so  erfolgt  die  Preisgabe  dieser  Daten  seitens  des  Nutzers  auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht  ausdrücklich  angeforderten  Informationen  ist  nicht  gestattet.  Rechtliche  Schritte  gegen  die  Versender  von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.</p>
        <h4>5. Rechtswirksamkeit dieses Haftungsausschlusses</h4>
        <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt. (Quelle: www.disclaimer.de)</p>
        </p>
      </div>
    </div>
  );
}

export default Impressum;