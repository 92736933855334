import { useState, useEffect } from "react";
import Navigation from '../navigation/navigation';
import NavigationMobile from '../navigation/navigationMobile';
import Start from '../sections/start/start';
import Band from '../sections/band/band';
import Gerry from '../sections/gerry/gerry';
import Manni from '../sections/manni/manni';
import Termine from '../sections/termine/termine';
import Galerie from "../sections/galerie/galerie";
import Kontaktformular from "../sections/kontaktformular/kontaktformular";
import Footer from '../sections/footer/footer';
import './homepage.css';

const Homepage = () => {
  const [isMobile, setIsMobile] = useState(true);

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
  }, []);

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);

      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (
    <div className="homepage">
      { isMobile ? <NavigationMobile /> : <Navigation /> }
      <section id="start">
        <Start />
      </section>
      <section id="band">
        <Band />
      </section>
      <section id="gerry">
        <Gerry />
      </section>
      <section id="manni">
        <Manni />
      </section>
      <section id="termine">
        <Termine />
      </section>
      <section id="galerie">
        <Galerie />
      </section>
      <section id="kontaktformular">
        <Kontaktformular />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Homepage;
