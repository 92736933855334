import logo from './../../images/logo_black_extended.svg';
import './start.css';

const Start = () => {
  return (
    <div className="start">
      <img src={logo} className="start-logo" alt="logo" />
    </div>
  );
}

export default Start;