import Homepage from './homepage/homepage';
import Impressum from './impressum/impressum';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
       <div>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
