import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import './galerie.css';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => 0);

  return (
    <button
      type="button"
      style={{ backgroundColor: 'white', border: 'none', width: '100%' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const Galerie = () => {
  const termin1 = require.context('./../../images/termine/hoffest_10052024', true);
  const termin1Bilder = termin1.keys().map(bild => termin1(bild));
  const termin2 = require.context('./../../images/termine/heimatfest_18052024', true);
  const termin2Bilder = termin2.keys().map(bild => termin2(bild));

  return (
    <div className="galerie">
      <p className="galerie-title">Galerie</p>
      <p className="galerie-satz">Klickt auf die einzelnen Auftritte, um unsere Bühnenmomente zu sehen! Psst, die Party beginnt erst, wenn ihr darauf drückt!</p>
      <Accordion>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="0">18.05.2024: 71. Heimat- und Blütenfest</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="galerie-bilder">
              {termin2Bilder.map((bild, index) => (
                <img key={index} src={bild} alt={`bild-${index}`} />
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <br/>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="1">10.05.2024: Hoffest</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div className="galerie-bilder">
              {termin1Bilder.map((bild, index) => (
                <img key={index} src={bild} alt={`bild-${index}`} />
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      </Accordion>
    </div>
  );
}

export default Galerie;