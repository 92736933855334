import manni from './../../images/manni.svg';
import './manni.css';

const Manni = () => {
  return (
    <div className="manni">
      <img src={manni} className="manni-image" alt="manni" />
      <div>
        <p className="manni-title">Manni</p>
        <p className="manni-text">
          Manni ist ein Musiker durch und durch. Als Mehrfachinstrumentalist und Sänger ist er eine tolle Bereicherung für Gerry. Als Mitbegründer der Tanz- und Showband Santiago ist er bekannt für seine freundliche Art und seine umgängliche Persönlichkeit.
        </p>
        <p className="manni-text">
          Seine flexible Stimme reicht von Rock bis Schlager und begeistert ein breites Publikum. Begleitet uns auf unserer musikalischen Reise mit Manni an vorderster Front! Privat ist Manni glücklich verheiratet mit Anja und stolzer Vater von drei Töchtern. In seiner Freizeit sitzt er stundenlang in seinem Studio und quält unter anderem die Gitarre.
        </p>
      </div>
    </div>
  );
}

export default Manni;