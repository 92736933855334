import Terminkarte from './terminkarte';
import hoffest from './../../images/termine/vergangen/hoffest.jpg';
import heimatfest from './../../images/termine/vergangen/heimatfest.jpg';
import sommerfest from './../../images/termine/vergangen/sommerfest.png';
import almLandau from './../../images/termine/bevorstehend/alm-landau.jpg';
import fkb from './../../images/termine/bevorstehend/fkb.png';
import alteSchmiede from './../../images/termine/bevorstehend/alte-schmiede.jpg';
import './termine.css';

const Termine = () => {
  return (
    <div className="termine">
      <p className="termine-title1">Bevorstehende Termine</p>
      {/* <p className="termine-satz">Neue Termine in Planung – freut euch auf mehr Musik!</p> */}
      <div className="termine-kacheln">
        <Terminkarte
          bild={almLandau}
          monat="Nov."
          uhrzeit="ab 20:00 Uhr"
          tag="8"
          eventname="Alm Landau"
          jahr="2024"
          adresse="Albert-Einstein-Straße 6a, 76829 Landau"
        />
        <Terminkarte
          bild={alteSchmiede}
          monat="Aug."
          uhrzeit="17:00 bis 22:00 Uhr"
          tag="23"
          eventname="Jahresfest"
          jahr="2025"
          adresse="Weinstube Alte Schmiede, Weinstraße 35, 76835 Rhodt unter Rietburg"
        />
      </div>
      <p className="termine-title2">Vergangene Termine</p>
      <div className="termine-kacheln">
        <Terminkarte
          bild={almLandau}
          monat="Sept."
          uhrzeit="ab 20:00 Uhr"
          tag="13"
          eventname="Alm Landau"
          jahr="2024"
          adresse="Albert-Einstein-Straße 6a, 76829 Landau"
        />
        <Terminkarte
          bild={fkb}
          monat="Aug."
          uhrzeit="20:00 bis 24:00 Uhr"
          tag="23"
          eventname="Fröhliches Kunterbunt (FKB)"
          jahr="2024"
          adresse="Am Schloss, 76887 Bad Bergzabern"
        />
        <Terminkarte
          bild={sommerfest}
          monat="Mai"
          uhrzeit="ab 11:00 Uhr"
          tag="30"
          eventname="Großes Sommerfest"
          jahr="2024"
          adresse="Gasthaus zur Grässelmühle, Sasbachwaldener Str. 64, 77880 Obersasbach"
        />
        <Terminkarte
          bild={heimatfest}
          monat="Mai"
          uhrzeit="17:00 bis 22:00 Uhr"
          tag="18"
          eventname="71. Heimat- und Blütenfest"
          jahr="2024"
          adresse="Weinstube Alte Schmiede, Weinstraße 35, 76835 Rhodt unter Rietburg"
        />
        <Terminkarte
          bild={hoffest}
          monat="Mai"
          uhrzeit="ab 19:00 Uhr"
          tag="10"
          eventname="Hoffest"
          jahr="2024"
          adresse="Heinzlerhof, Ufgaustraße 13, 76275 Ettlingen"
        />
      </div>
    </div>
  );
}

export default Termine;