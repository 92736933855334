import './terminkarte.css';

const Terminkarte = ({ bild, monat, uhrzeit, tag, eventname, jahr, adresse }) => {
  return (
    <div className="terminkarte">
      <div className="terminkarte-bild">
        <img src={bild} alt="termin" />
      </div>
      <div className="terminkarte-daten">
        <div className="terminkarte-monat">{monat}</div>
        <div className="terminkarte-uhrzeit">{uhrzeit}</div>
        <div className="terminkarte-tag">{tag}</div>
        <div className="terminkarte-eventname">{eventname}</div>
        <div className="terminkarte-jahr">{jahr}</div>
        <div className="terminkarte-adresse">{adresse}</div>
      </div>
    </div>
  );
}

export default Terminkarte;