import './band.css';

const Band = () => {
  return (
    <div className="band">
      <div>
        <p className="band-title">Willkommen bei TopSantiago</p>
        <p className="band-text">
          TopSantiago ist die kraftvolle Fusion zweier musikalischer Titanen aus Bayern und der Pfalz: Gerry und Manni. Ihre musikalische Reise begann in erfolgreichen Bands ihrer Heimatregion, doch nun vereinen sie ihre Talente, um neue kreative Höhen zu erklimmen.
        </p>
        <p className="band-text">
          Gerry, mit seiner kraftvollen Stimme und virtuosen Gitarrenarbeit, bringt die rockige Seele Bayerns auf die Bühne, während Manni, mit pulsierenden Rhythmen und unverwechselbaren Basslinien, die warmen Klänge der Pfalz verkörpert.
        </p>
        <p className="band-text">
          Von energiegeladenen Riffs bis zu einfühlsamen Balladen - TopSantiago erschafft einzigartige Klanglandschaften, die das Publikum in ihren Bann ziehen. Begleitet TopSantiago auf ihrer musikalischen Reise, wo die Pfalz auf Bayern trifft und Musik zu neuen Höhen aufbricht.
        </p>
      </div>
    </div>
  );
}

export default Band;