import gerry from './../../images/gerry.svg';
import './gerry.css';

const Gerry = () => {
  return (
    <div className="gerry">
      <img src={gerry} className="gerry-image" alt="gerry" />
      <div>
        <p className="gerry-title">Gerry</p>
        <p className="gerry-text">
          Gebürtig aus Freilassing in Bayern, stieß Gerry 1971 zu der Band, damals bekannt als "Magic Five", die später den Namen "Die TOPS" annahm. Seine unverwechselbare Stimme prägte den Erfolg der TOPS maßgeblich. Hits wie “Hallo Mädchen", “Hand auf´s Herz” und "Du bist mein kleines Paradies" eroberten die Herzen der Fans und die deutschen Hitparaden im Sturm.
        </p>
        <p className="gerry-text">
          Gerrys musikalisches Talent erstreckt sich über Bassgitarre, Saxophon und Keyboard. Inspiriert von Größen wie Tom Jones, Engelbert und Elvis, interpretiert er ihre Songs auf unnachahmliche Weise. Seine Version von "My Way" hinterlässt Gänsehaut.
        </p>
        <p className="gerry-text">
          Abseits der Bühne ist Gerry mit Sabine verheiratet und hat eine Tochter und einen Sohn, der in seine Fußstapfen im Tonstudio tritt. Neben seinen eigenen Hits schrieb er auch für namhafte Künstler wie die Flippers und Marc Pircher. In seiner Freizeit entspannt er beim Angeln in den Rheinauen auf seinem Boot.
        </p>
      </div>
    </div>
  );
}

export default Gerry;