import { useState } from "react";
import { NavHashLink } from 'react-router-hash-link';
import bars from './../images/bars.svg';
import xmark from './../images/xmark.svg';
import './navigationMobile.css';

const NavigationMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navigationMobile">
      <div className="burger-menu" onClick={toggleMenu}>
        { isOpen ? (
          <img src={xmark} className="xmark" alt="xmark" />
        ) : (
          <img src={bars} className="bars" alt="bars" />
        )}
      </div>
      { isOpen && (
        <nav className="navigationMobile-open">
          <NavHashLink
            smooth to="/#start"
            className="normal"
            onClick={toggleMenu}
          >
            Start
          </NavHashLink>
          <NavHashLink
            smooth to="/#band"
            className="normal"
            onClick={toggleMenu}
          >
            Band
          </NavHashLink>
          <NavHashLink
            smooth to="/#gerry"
            className="normal"
            onClick={toggleMenu}
          >
            Gerry
          </NavHashLink>
          <NavHashLink
            smooth to="/#manni"
            className="normal"
            onClick={toggleMenu}
          >
            Manni
          </NavHashLink>
          <NavHashLink
            smooth to="/#termine"
            className="normal"
            onClick={toggleMenu}
          >
            Termine
          </NavHashLink>
          <NavHashLink
            smooth to="/#galerie"
            className="normal"
            onClick={toggleMenu}
          >
            Galerie
          </NavHashLink>
          <NavHashLink
            smooth to="/#kontaktformular"
            className="normal"
            onClick={toggleMenu}
          >
            Kontaktformular
          </NavHashLink>
        </nav>
      )}
    </div>
  );
}

export default NavigationMobile;