import { Link } from 'react-router-dom';
import logo from './../../images/logo_red.svg';
import telefon from './../../images/telefon.svg';
import brief from './../../images/brief.svg';
import facebook from './../../images/facebook.svg';
import instagram from './../../images/instagram.svg';
import './footer.css';

const Footer = () => {
  return(
    <div className="footer">
      <div className="footer-oben">
        <div className="footer-logo">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <div className="footer-kontakt">
          <p>Kontakt</p>
          <div className="footer-kontakt-container">
            <img src={telefon} alt="telefon" />
            <div className="footer-kontakt-text">
              <div>Gerry Trecha</div>
              <div>0172 7353265</div>
            </div>
          </div>
          <div className="footer-kontakt-container">
            <img src={telefon} alt="telefon" />
            <div className="footer-kontakt-text">
              <div>Manfred Schwan</div>
              <div>0176 60882679</div>
            </div>
          </div>
          <div className="footer-kontakt-container">
            <img src={brief} alt="brief" />
            <div className="footer-kontakt-text">
              <div>info@topsantiago.de</div>
            </div>
          </div>
        </div>
        <div className="socialmedia">
          <p>Folge uns</p>
          <a href="https://www.facebook.com/profile.php?id=61557553577169">
            <img src={facebook} className="facebook-icon" alt="facebook" />
          </a>
          <a href="https://www.instagram.com/topsantiago.official/">
            <img src={instagram} className="instagram-icon" alt="instagram" />
          </a>
        </div>
      </div>
      <div className="footer-unten">
        <Link className="impressum-link" to="/impressum">
          Impressum
        </Link>
        <div>Mit ❤ gemacht von Laura Welther © 2024</div>
      </div>
    </div>
  );
}

export default Footer;