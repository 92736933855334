import React from 'react';
import emailjs from 'emailjs-com';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import './kontaktformular.css';

const serviceId = process.env.REACT_APP_SERVICE_ID;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

const Kontaktformular = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, publicKey)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Deine Nachricht wurde erfolgreich versendet.'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Oh, etwas ist schief gegangen.',
          text: error.text,
        })
      });
    e.target.reset()
  };
  
  return (
    <div className="kontaktformular">
      <p className="kontaktformular-title">Kontaktformular</p>
      <Form onSubmit={handleOnSubmit}>
        <Form.Field className="feld">
          <label className="custom-label">Name</label>
          <Input
            id='form-input-control-name'
            name='user_name'
            placeholder='Name...'
            required
          />
        </Form.Field>
        <Form.Field className="feld">
          <label className="custom-label">E-Mail-Adresse</label>
          <Input
            id='form-input-control-email'
            name='user_email'
            placeholder='E-Mail-Adresse...'
            required
          />
        </Form.Field>
        <Form.Field className="feld">
          <label className="custom-label">Nachricht</label>
          <TextArea
            id='form-textarea-control-message'
            name='user_message'
            placeholder='Nachricht...'
            required
          />
        </Form.Field>
        <Button className="kontaktformular-button" type='submit'>Absenden</Button>
      </Form>
    </div>
  );
}

export default Kontaktformular;