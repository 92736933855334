import { NavHashLink } from 'react-router-hash-link';
import './navigation.css';

const Navigation = () => {
  return (
    <nav className="navigation">
      <NavHashLink
        smooth to="/#start"
        className="normal"
      >
        Start
      </NavHashLink>
      <NavHashLink
        smooth to="/#band"
        className="normal"
      >
        Band
      </NavHashLink>
      <NavHashLink
        smooth to="/#gerry"
        className="normal"
      >
        Gerry
      </NavHashLink>
      <NavHashLink
        smooth to="/#manni"
        className="normal"
      >
        Manni
      </NavHashLink>
      <NavHashLink
        smooth to="/#termine"
        className="normal"
      >
        Termine
      </NavHashLink>
      <NavHashLink
        smooth to="/#galerie"
        className="normal"
      >
        Galerie
      </NavHashLink>
      <NavHashLink
        smooth to="/#kontaktformular"
        className="normal"
      >
        Kontaktformular
      </NavHashLink>
    </nav>
  );
}

export default Navigation;